import React, { useEffect } from "react";
import "./ContactUs.css";
const ContactUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="contact-us">
        <div className="section-header-wrapper">
          <div className="container">
          <div className="section-header">
            <h1>Contact Us</h1>
            <p>
              Start your journey towards that perfect getaway by contacting us
              at the earliest with us as <br /> we believe in making travelling
              effortless for all parties involved.
            </p>
          </div>
          </div>
          </div>
          <div className=" container">

          <div className="contact-info">
            <div className="contact-info-wrapper">
              <div className="contact-info-item">
                <div className="contact-info-icon">
                  <i className="fas fa-home"></i>
                </div>

                <div className="contact-info-content">
                  <h4>Address</h4>
                  <p>
                    <strong>Mapathink Private Limited,</strong>
                    <br />
                    India Office - Office no. - 205, 2nd floor, 61 building, 
                    <br />
                    Vijay block pillar number 51, Laxmi Nagar, 110092, New Delhi, India
                  </p>
                  <br />
                  <br />
                  <p>
                    <strong>Mapathink Co., Ltd.,</strong>
                    <br />
                    Thailand Office - Office No. 308, 808/3 Soi Sukhumvit 55,  
                    <br />
                    Khlong Tan Nuea subdistrict, Wathana district, Bangkok 10110, Thailand
                  </p>
                </div>
              </div>

              <div className="contact-info-item">
                <div className="contact-info-icon">
                  <i className="fas fa-phone"></i>
                </div>

                <div className="contact-info-content">
                  <h4>Phone</h4>
                  <p>9716621688</p>
                </div>
              </div>

              <div className="contact-info-item">
                <div className="contact-info-icon">
                  <i className="fas fa-envelope"></i>
                </div>

                <div className="contact-info-content">
                  <h4>Email</h4>
                  <p>Info@mapathink.com</p>
                </div>
              </div>
            </div>
            <div className="contact-form">
              <form action="" id="contact-form">
                <h2>Send Message</h2>
                <div className="input-box">
                  <input type="text" required="true" name="" />
                  <span>Full Name</span>
                </div>

                <div className="input-box">
                  <input type="email" required="true" name="" />
                  <span>Email</span>
                </div>

                <div className="input-box">
                  <textarea required="true" name=""></textarea>
                  <span>Type your Message...</span>
                </div>

                <div className="input-box">
                  <input type="submit" value="Send" name="" />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
